<template>
    <div class="form__group">
        <input 
            class="form__field"
            :class="{error: error}"
            :value="value"
            :type="type"
            :placeholder="placeholder"
            @input="$emit('eventInput', $event.target.value)"
        >

        <transition name="fade">
            <div v-if="error" class="form__group__error">
                {{ errorText }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Field',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        errorText: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    .form {

        &__group {
            margin: 0 0 10px 0;
        }

        &__field {
            display: block;
            height: 50px;
            width: 100%;
            padding: 16px 20px;
            border: 1px solid #DBDFE1;
            border-radius: 15px;
            background-color: #fff;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            color: $gray;
            box-shadow: none;
            transition: 150ms ease-in-out;

            &::placeholder {
                transition: 75ms ease-in-out;
            }

            &.error {
                border-color: $red;
            }
        }
    }
</style>