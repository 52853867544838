<template>
    <header class="header">
        <img class="header__logotype" :src="require('@/assets/logo.svg')" alt="Easy Dating" />
        <picture class="header__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/bg_1440.png')">
            <source media="(min-width:1024px)" :srcset="require('@/assets/bg_1024.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/bg_320.png')">
            <img :src="require('@/assets/bg_1440.png')" alt="Easy Dating">
        </picture>
        <Registration className="header__form">
            <h1 class="title title--h1">
                Online <span>dating site</span>
            </h1>
        </Registration>
    </header>
</template>

<script>
import Registration from '@/components/Registration';

export default {
    name: 'Header',
    components: {
        Registration,
    }
}
</script>

<style lang="scss">
    .header {
        position: relative;

        @include xs-sm {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include md {
            padding-left: 92px;
        }

        @include lg {
            padding-left: 134px;
        }

        @include xl {
            padding-left: 173px;
        }

        &__logotype {

            @include xs {
                width: 120px;
                margin: 20px auto;
            }

            @include xs-sm {
                height: auto;
            }

            @include sm {
                width: 150px;
                margin: 30px auto;
            }

            @include md {
                width: 130px;
                margin: 43px 0 40px 0;
            }

            @include lg {
                width: 156px;
                margin: 60px 0 84px 0;
            }

            @include xl {
                width: 185px;
                margin: 60px 0 175px 74px;
            }
        }

        &__background {
            display: block;
            width: 100%;

            @media (min-width: 1024px) {
                z-index: -1;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;

                @media (min-width: 1024px) {
                    height: 100%;
                    object-fit: cover;
                    object-position: right bottom;
                }
            }
        }

        &__form {

            @include xs-sm {
                padding: 0 20px;
                text-align: center;
            }

            @include md {
                width: 25.39%;
                min-width: 260px;
                padding-bottom: 111px;
            }

            @include lg {
                width: 328px;
                padding-bottom: 171px;
            }

            @include xl {
                width: 407px;
                padding-bottom: 298px;
            }
        }
    }
</style>