<template>
    <form class="form" :class="className">
        <slot></slot>

        <Input 
            type="text"
            placeholder="Your name"
            :value="form.name"
            :error="$v.form.name.$error"
            @eventInput="($v.form.name.$model = $event, delayTouch($v.form.name))"
        />
        <Input 
            type="email"
            placeholder="E-mail"
            :value="form.email"
            :error="$v.form.email.$error"
            @eventInput="($v.form.email.$model = $event, delayTouch($v.form.email))"
        />
        <Checkbox 
            placeholder="I agree with License agreement"
            :error="$v.form.sign_agree.$error"
            :checked="form.sign_agree"
            @eventChange="$v.form.sign_agree.$model = $event"
        />
        <Button 
            class="button"
            text="SIGN UP"
            @eventClick="submit"
        />
    </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs, minLength, maxLength } from '@vuelidate/validators';
import Input from '@/components/Base/Input';
import Checkbox from '@/components/Base/Checkbox';
import Button from '@/components/Base/Button';

export default {
    name: 'Registration',
    props: {
        className: {
            type: String,
            default: '',
        }
    },
    components: {
        Input,
        Checkbox,
        Button
    },
    setup: () => ({
        $v: useVuelidate()
    }),
    data: () => ({
        form: {
            name: null,
            email: null,
            sign_agree: true,
        },
        wMap: new WeakMap(),
    }),

    validations: () => ({
        form: {
            name: { required, minLength: minLength(3), maxLength: maxLength(15) },
            email: { required, email },
            sign_agree: { sameAs: sameAs(true) }
        }
    }),
    created() {
        this.getParams();
    },
    methods: {
        async getIP() {
            try {
                const response = await fetch('https://api.ipify.org/?format=json');
                const { ip } = await response.json();

                return ip;
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        async getParams() {
            const ip = await this.getIP();
            const url = new URL(document.location);
            const params = url.searchParams;

            Object.assign(this.form, {
                'pid': params.get('pid') || null,
                'pid_sid': params.get('sid') || null,
                'ip': ip,
            });
        },
        delayTouch($v) {
            $v.$reset();
            if (this.wMap.has($v)) clearTimeout(this.wMap.get($v));
            this.wMap.set($v, setTimeout($v.$touch, 1000));
        },
        async submit() {
            try {
                this.$v.$touch();
                if (this.$v.$error) return;

                const data = new FormData();
                for (const key in this.form) {
                    data.append(key, this.form[key]);
                }

                const query = await fetch('/api/?action=registration', {
                    method: 'POST',
                    body: data,
                });
                const { loc } = await query.json();
                window.location = loc;
            } catch(error) {
                console.error(error);
            }
        }
    }
}
</script>

<style>

</style>