<template>
    <footer class="footer">
        <picture class="footer__background">
            <source media="(min-width:1024px)" :srcset="require('@/assets/footer-lg.svg')">
            <source media="(min-width:320px)" :srcset="require('@/assets/footer.svg')">
            <img :src="require('@/assets/footer.svg')" alt="Easy Dating">
        </picture>
        <picture class="footer__background-2">
            <source media="(min-width:1024px)" :srcset="require('@/assets/footer-1-lg.svg')">
            <source media="(min-width:320px)" :srcset="require('@/assets/footer-1.svg')">
            <img :src="require('@/assets/footer-1.svg')" alt="Easy Dating">
        </picture>

        <div class="footer__wrapper">
            <div class="footer__payments">
                <img 
                    v-for="(payment, index) in payments"
                    :key="index"
                    :src="payment"
                    alt="#"
                >
            </div>
            <div class="footer__copyright">
                <img class="footer__logotype" :src="require('@/assets/logo-footer.svg')" alt="Easy Dating" />
                Easydating.com 2008-{{ (new Date()).getFullYear() }} © All rights reserved
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        payments: [
            require('@/assets/p-1.png'),
            require('@/assets/p-2.png'),
            require('@/assets/p-3.png'),
            require('@/assets/p-4.png')
        ]
    })
}
</script>

<style lang="scss">
    .footer {
        position: relative;
        overflow: hidden;

        @include xs {
            padding: 51px 20px 35px 20px;
        }

        @include sm {
            padding: 63px 50px 45px 50px;
        }

        @include md {
            padding: 68px 75px 51px 75px;
        }

        @media (min-width: 1440px) {
            padding: 55px 75px 39px 75px;
        }

        &__wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            @include xs {
                flex-direction: column;
                justify-content: center;
            }

            @media (min-width: 768px) {
                flex-direction: row-reverse;
                justify-content: space-between;
            }

            @media (min-width: 1440px) {
                max-width: 980px;
                margin: 0 auto;
            }
        }

        &__background,
        &__background-2 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        &__background-2 {
            z-index: 1;
        }

        &__background {
            z-index: 2;
        }

        &__payments {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @include xs {
                margin: 0 -5px 24px -5px;
                width: calc(100% + 10px);
            }

            @media (min-width: 768px) {
                margin: 0 -12.5px;
            }

            img {
                max-width: 90px;
                height: fit-content;

                @include xs {
                    margin: 0 5px;
                    width: calc(25% - 10px);
                }

                @media (min-width: 768px) {
                    margin: 0 12.5px;
                    width: calc(25% - 25px);
                }
            }
        }

        &__copyright {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #858999;

            @media (min-width: 768px) {
                margin-right: 20px;
            }
        }

        &__logotype {
            flex-shrink: 0;
            margin-right: 19px;
        }
    }
</style>