<template>
  <button
    @click.prevent="$emit('eventClick')"
    :type="type"
    class="button"
    :disabled="disabled"
    :class="classNames"
  >
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    classNames: String,
    disabled: [String, Boolean],
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    eventclick() {
      this.$emit('eventclick');
    }
  }
};
</script>

<style lang="scss">
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 19px 20px;
    border: none;
    border-radius: 50px;
    background: $blue;
    box-shadow: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    transition: 150ms ease-in-out;
    text-decoration: none;
    cursor: pointer;

    img {
      margin-left: 12px;
    }
  }
</style>