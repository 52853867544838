<template>
    <section class="why-us">
        <img class="why-us__background" :src="require('@/assets/why-us.svg')" alt="Why Us">
        <h2 class="title title--h2 ta-center">Why us?</h2>
        <div class="text ta-center">
            It's simple, we did everything to make your experience as positive as possible
        </div>

        <div class="why-us__list">
            <picture class="why-us__bottom">
                <source media="(min-width:768px)" :srcset="require('@/assets/why-us-b-lg.svg')">
                <source media="(min-width:320px)" :srcset="require('@/assets/why-us-b.svg')">
                <img :src="require('@/assets/why-us-b.svg')" alt="Why Us">
            </picture>
            <div 
                v-for="(item, index) in list"
                :key="index"
                class="why-us__item"
            >
                <div class="why-us__item__icon">
                    <img :src="item.icon" alt="">
                </div>
                <img :src="require('@/assets/shadow.svg')" alt="Shadow" class="why-us__item__shadow" />
                <div class="why-us__item__title">{{ item.title }}</div>
                <div class="why-us__item__description text">
                    {{ item.description }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyUs',
    data: () => ({
        list: [
            {
                icon: require('@/assets/protection.svg'),
                title: 'Protection',
                description: 'You safety is provided by leading anti-scam systems in the industry'
            }, {
                icon: require('@/assets/verification.svg'),
                title: 'Verification',
                description: 'All members are personally confirmed by our staff to prove they are real'
            }, {
                icon: require('@/assets/communication.svg'),
                title: 'Communication',
                description: 'Chat, send letters, share photos and videos, take part in live broadcast'
            }
        ]
    })
}
</script>

<style lang="scss">
    .why-us {
        position: relative;
        @include xs {
            padding: 74px 20px 0 20px;
        }

        @include sm {
            padding: 80px 50px 0 50px;
        }

        @include md {
            padding: 40px 50px 0 50px;
        }

        @include lg {
            padding: 33px 100px 0 100px;
        }

        @include xl {
            padding: 40px 20px 0 20px;
        }

        &__background {
            z-index: -1;
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;

            @include xs {
                top: 0;
            }

            @include sm {
                top: -80px;
            }

            @include md {
                top: -110px;
            }

            @include lg {
                top: -175px;
            }

            @include xl {
                top: -250px;
            }
        }

        &__bottom {
            z-index: -1;
            position: absolute;
            height: 100%;
            object-fit: cover;
            object-position: center;

            img {
                width: 100%;
            }

            @include xs {
                top: 68px;
                left: -20px;
                width: calc(100% + 40px);
            }

            @include sm {
                top: 75px;
                left: -50px;
                width: calc(100% + 100px);
            }

            @include md {
                top: 80px;
                left: -50px;
                width: calc(100% + 100px);
            }

            @include lg {
                top: 40px;
                left: -100px;
                width: calc(100% + 200px);
            }

            @include xl {
                top: 35px;
                left: 50%;
                width: 100vw;
                transform: translateX(-50%);
            }
        }

        &__list {
            position: relative;
            display: flex;
            flex-wrap: wrap;

            @include xs {
                padding: 0 40px;
                justify-content: center;
            }

            @media (min-width: 1024px) {
                width: 100%;
                margin: 0 auto;
            }

            @include sm {
                padding: 0 30px;
            }

            @include md {
                padding: 0 80px;
            }

            @include lg {
                padding: 0 115px;
            }

            @include xl {
                max-width: 1430px;
                padding: 0 125px;
            }

            @media (min-width: 768px) {
                justify-content: space-between;
            }

            &::before {
                content: '';
                z-index: 2;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: #fff;
                border-radius: 20px;

                @include xs {
                    height: calc(100% - 68px);
                }

                @include sm {
                    height: calc(100% - 75px);
                }

                @include md {
                    height: calc(100% - 79px);
                }

                @include lg {
                    height: calc(100% - 39px);
                }

                @include xl {
                    height: calc(100% - 35px);
                }
            }

            &::after {
                content: '';
                z-index: 1;
                position: absolute;
                bottom: -20px;
                left: 50%;
                background: #DDF3F3;
                filter: blur(70px);
                border-radius: 20px;
                transform: translateX(-50%);

                @include xs {
                    width: calc(100% - 46px);
                    height: 195px;
                }

                @include sm {
                    width: calc(100% - 102px);
                    height: 232px;
                }

                @include md {
                    width: calc(100% - 102px);
                    height: 238px;
                }

                @media (min-width: 1440px) {
                    width: calc(100% - 152px);
                    height: 248px;
                }
            }
        }

        &__item {
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            @include xs {
                width: 100%;
            }

            @include xs-sm {
                margin: 0 0 40px 0;
            }

            @media (min-width: 768px) {
                width: 30%;
            }

            @include md {
                margin: 0 0 50px 0;
            }

            @include lg {
                margin: 0 0 64px 0;
            }

            @include xl {
                margin: 0 0 80px 0;
            }

            &__icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;

                img {
                    width: auto;
                    height: 54.4%;
                }

                @include xs {
                    width: 90px;
                    height: 90px;
                    margin: 0 0 5px 0;
                }

                @include sm {
                    margin: 0 0 14px 0;
                }

                @include md {
                    margin: 0 0 20px 0;
                }

                @include lg {
                    margin: 0 0 35px 0;
                }

                @include xl {
                    margin: 0 0 41px 0;
                }

                @media (min-width: 768px) and (max-width: 1919px) {
                    width: 110px;
                    height: 110px;
                }

                @include xl {
                    width: 123px;
                    height: 123px;
                }
            }

            &:nth-child(3n - 2) {

                .why-us__item__icon {
                    background: linear-gradient(150deg, #FFD978 5.77%, #FFB359 95.53%);
                }
            }

            &:nth-child(3n - 1) {

                .why-us__item__icon {
                    background: linear-gradient(240deg, #FFD978 5.77%, #FFB359 95.53%);
                }
            }

            &:nth-child(3n) {

                .why-us__item__icon {
                    background: linear-gradient(60deg, #FFD978 5.77%, #FFB359 95.53%);
                }
            }

            &__title {
                margin: 0 0 12px 0;
                color: $black;

                @include xs-sm {
                    font-weight: 600;
                    font-size: 22px;
                }

                @media (min-width: 1024px) {
                    font-weight: 700;
                }

                @include md {
                    font-size: 30px;
                }

                @include lg {
                    font-size: 30px;
                }

                @include xl {
                    font-size: 36px;
                }
            }

            &__description {
                
                @include xs-sm {
                    max-width: 200px;
                }

                @include md {
                    max-width: 220px;
                }

                @include lg {
                    max-width: 265px;
                }

                @include xl {
                    max-width: 268px;
                }

                @media (max-width: 1439px) {
                    font-size: 14px;
                }
            }
        }

        > .text {
            width: 100%;
            max-width: 366px;
            margin: 0 auto;

            @include xs {
                margin-bottom: 50px;
            }

            @include sm {
                margin-bottom: 40px;
            }

            @include md {
                margin-bottom: 50px;
            }

            @include lg {
                margin-bottom: 85px;
            }

            @include xl {
                margin-bottom: 90px;
            }
        }
    }
</style>