<template>
    <section class="our-girls">
        <img class="our-girls__background" :src="require('@/assets/our-girls.svg')" alt="Our Girls">
        <h2 class="title title--h2 ta-center">Our girls</h2>
        <div class="text ta-center">
            <p>Over 2358 singles have already found their soulmate on our site</p>
        </div>

        <div v-if="girls" class="our-girls__list">
            <div class="our-girls__prev" @click="$refs.swiper.$swiper.slidePrev()">
                <img :src="require('@/assets/arrow-prev.svg')" alt="Prev">
            </div>
            <div class="our-girls__next" @click="$refs.swiper.$swiper.slideNext()">
                <img :src="require('@/assets/arrow-next.svg')" alt="Next">
            </div>
            <swiper 
                :options="swiperOption" 
                ref="swiper"
                @slideChange="onSlideChange"
            >
                <swiper-slide
                    v-for="(item, key) in girls"
                    ref="swiperBox"
                    :key="key"
                    class="our-girls__item ta-center"
                >
                    <img class="our-girls__item__image" :src="applyImgSrc(item.photo)" :alt="item.name">
                    <div class="our-girls__item__info">
                        <div class="our-girls__item__title">{{ item.name }}</div>
                        <div class="our-girls__item__age text">{{ item.age }} years</div>
                        <div class="our-girls__item__interests">
                            <div 
                                v-for="(icon, index) in getInterest()"
                                :key="index"
                                class="our-girls__item__interes"
                            >
                                <img 
                                    :src="icon"
                                    :alt="index"
                                />
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <div class="button" @click="$modal.show('join-modal')">
            see All girls
            <img :src="require('@/assets/arrow.svg')" alt="arrow" />
        </div>


        <modal 
            name="join-modal"
            width="100%"
            height="auto"
        >
            <div class="popup">
                <svg 
                    @click="$modal.hide('join-modal')"
                    class="popup__close" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8892 1.11091C19.2797 1.50144 19.2797 2.1346 18.8892 2.52513L2.52562 18.8887C2.13509 19.2792 1.50193 19.2792 1.1114 18.8887C0.720879 18.4982 0.720879 17.865 1.1114 17.4745L17.475 1.11092C17.8655 0.720391 18.4987 0.720391 18.8892 1.11091Z" fill="#DBDFE1"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.11082 1.11091C0.720293 1.50144 0.720291 2.1346 1.11082 2.52513L17.4744 18.8887C17.8649 19.2792 18.4981 19.2792 18.8886 18.8887C19.2791 18.4982 19.2791 17.865 18.8886 17.4745L2.52503 1.11092C2.13451 0.720391 1.50134 0.720391 1.11082 1.11091Z" fill="#DBDFE1"/>
                </svg>
                <img class="popup__logotype" :src="require('@/assets/logo.svg')" alt="Easy Dating" />
                <div class="popup__title">
                    It's very simple. <span>Try it!</span>
                </div>
                <Registration className="popup__form" />
            </div>
        </modal>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import sampleSize from 'lodash.samplesize';
import Registration from '@/components/Registration';

import 'swiper/swiper.scss';

export default {
    name: 'OurGirls',
    components: {
        Swiper,
        SwiperSlide,
        Registration
    },
    data: () => ({
        swiper: null,
        swiperOption: {
            slidesPerView: 'auto',
            breakpoints: {
                1920: {
                    loop: true,
                    spaceBetween: 20,
                    centeredSlides: true,
                    slidesPerView: 5,
                    navigation: {
                        nextEl: '.our-girls__next',
                        prevEl: '.our-girls__prev'
                    }
                },
                1440: {
                    loop: true,
                    spaceBetween: 20,
                    centeredSlides: true,
                    slidesPerView: 3,
                    navigation: {
                        nextEl: '.our-girls__next',
                        prevEl: '.our-girls__prev'
                    }
                },
                768: {
                    loop: true,
                    spaceBetween: 20,
                    centeredSlides: true,
                    slidesPerView: 3,
                    navigation: {
                        nextEl: '.our-girls__next',
                        prevEl: '.our-girls__prev'
                    }
                },
                320: {
                    slidesPerView: 1.33,
                    spaceBetween: 20,
                }
            }
        },
        girls: null,
        interests: [
            require('@/assets/1.svg'),
            require('@/assets/2.svg'),
            require('@/assets/3.svg'),
            require('@/assets/4.svg'),
            require('@/assets/5.svg'),
            require('@/assets/6.svg'),
            require('@/assets/7.svg'),
            require('@/assets/8.svg'),
        ],
    }),
    created() {
        this.getGirls();
    },
    mounted() {
        this.onSlideChange();
    },
    methods: {
        async getGirls() {
            try {
                const query = await fetch('/api/?action=get_profiles', {
                    method: 'POST',
                });
                this.girls = await query.json();
            } catch(error) {
                console.error(error);
            }
        },
        getInterest() {
            return sampleSize(this.interests, 3);
        },
        applyImgSrc(url) {
			return '//storage.victoriyaclub.com' + url;
		},
        onSlideChange() {
            const activeIndex = this.$refs.swiper.$swiper.activeIndex;
            document.querySelectorAll('.swiper-slide').forEach((item, index) => {
                if (index >= activeIndex - 2 && index <= activeIndex + 2) {
                    item.classList.add('visible');
                } else {
                    item.classList.remove('visible');
                }
            });
        }
    }
}
</script>

<style lang="scss">
    .popup {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 520px;
        padding: 58px 80px 70px 80px;
        background: #fff;

        @media (max-width: 768px) {
            padding: 28px 25px 30px 25px;
        }

        &__close {
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;

            @media (max-width: 768px) {
                top: 20px;
                right: 20px;
            }
        }

        &__logotype {
            margin: 0 0 44px 0;

            @media (max-width: 768px) {
                width: 140px;
                margin: 0 0 30px 0;
            }
        }

        &__title {
            margin-bottom: 30px;
            font-size: 30px;
            line-height: 36px;
            font-weight: 600;
            color: $black;

            span {
                color: $red;
            }

            @media (max-width: 768px) {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }

        &__form {
            width: 100%;
        }
    }

    .vm {

        &--modal {
            display: flex;
            justify-content: center;
            background-color: transparent !important;
            box-shadow: none !important;

            @media (max-width: 768px) {
                padding: 10px;
            }
        }
    }

    .our-girls {
        position: relative;

        .button {
            margin: 0 auto;

            @media (max-width: 1919px) {
                width: 280px;
            }

            @media (min-width: 1920px) {
                width: 330px;
            }
        }

        @include xs {
            padding: 60px 20px 0 20px;
            margin: 0 0 50px 0;
        }

        @include sm {
            padding: 80px 50px 0 50px;
            margin: 0 0 80px 0;
        }

        @include md {
            padding: 100px 50px 0 50px;
            margin: 0 0 80px 0;
        }

        @include lg {
            padding: 120px 50px 0 50px;
            margin: 0 0 100px 0;
        }

        @include xl {
            padding: 130px 135px 0 135px;
            margin: 0 0 100px 0;
        }

        &__prev,
        &__next {

            @include xs {
                display: none;
            }

            @media (min-width: 768px) {
                z-index: 10;
                position: absolute;
                top: calc(50% - 44px);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                background: #fff;
                border-radius: 100%;
                filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
                cursor: pointer;
                transition: 150ms ease-in-out;
            }

            @media (min-width: 768px) and (max-width: 1439px) {

                &:active {
                    filter: none;
                } 
            }

            @media (min-width: 1440px) {

                &:hover {
                    filter: none;
                }
            }
        }

        &__prev {
            left: 26px;
        }

        &__next {
            right: 26px;
        }

        &__background {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }

        &__list {
            position: relative;

            @include xs {
                margin: 0 -20px;
            }

            @include sm {
                margin: 0 -50px 22px -50px;
            }

            @include md {
                margin: 0 -50px 22px -50px;
            }

            @include lg {
                margin: 0 -50px 18px -50px;
            }

            @include xl {
                margin: 0 -135px 28px -135px;
            }
        }

        &__item {
            position: relative;
            padding-bottom: 40px;
            transition: 200ms ease-in-out;

            @include xs-sm {
                width: 210px;
            }

            @include xs {
                height: 395px;
            }

            @media (min-width: 768px) and (max-width: 1919px) {

                &:not(.swiper-slide-prev):not(.swiper-slide-active):not(.swiper-slide-next) {
                    opacity: 0.2;
                }
            }

            @media (min-width: 1920px) {

                &:not(.swiper-slide-prev):not(.swiper-slide-active):not(.swiper-slide-next):not(.visible) {
                    opacity: 0.2;
                }
            }

            @include sm {
                height: 450px;
            }

            @include md {
                height: 463px;
            }

            @include lg {
                width: 287px;
                height: 553px;
            }

            @include xl {
                
            }

            &__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
                box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
                transition: 150ms ease-in-out;
            }

            &__info {
                position: absolute;
                bottom: 30px;
                left: 50%;
                width: 92.38%;
                padding: 12px 22px 9px 22px;
                background: #fff;
                border-radius: 17px;
                transform: translateX(-50%);
            }

            &__title {
                font-size: 20px;
                line-height: 1.2;
                font-weight: 600;
            }

            &__age {
                font-size: 12px;
            }

            &__interests {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__interes {
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-child) {

                    &::after {
                        content: '';
                        display: block;
                        height: 13px;
                        width: 1px;
                        margin: 0 6px;
                        background: #F4F4F4;
                    }
                }
            }
        }

        > .text {
            width: 100%;
            max-width: 366px;
            margin: 0 auto;

            @include xs {
                margin-bottom: 40px;
            }

            @include sm {
                margin-bottom: 40px;
            }

            @include md {
                margin-bottom: 50px;
            }

            @include lg {
                margin-bottom: 85px;
            }

            @include xl {
                margin-bottom: 90px;
            }
        }
    }

    .swiper {

        &-container {

            @include xs {
                padding: 0 20px;
            }

            @include sm {
                padding: 0 50px;
            }

            @include md {
                padding: 0 50px;
            }
            
            @include lg {
                padding: 0 100px;
            }

            @include xl {
                padding: 0 135px;
            }
        }

        &-button-prev,
        &-button-next {
            display: none;
        }
    }
</style>