<template>
    <label class="form__checkbox" :class="{error: error}">
        <input
            :checked="checked"
            type="checkbox"
            @change="$emit('eventChange', $event.target.checked)"
        >
        <pre></pre>
        {{ placeholder }}
    </label>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        checked: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
    .form__checkbox {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 14px;

        @include xs {
            justify-content: center;
            margin: 20px 0 24px 0;
        }

        margin: 16px 0 24px 0;


        input {
            display: none;

            &:checked {

                ~ pre::before {
                    opacity: 1;
                }
            }
        }

        pre {
            position: relative;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin: 0 10px 0 0;
            background: #FFFFFF;
            border: 1px solid #DBDFE1;
            border-radius: 3px;
            transition: 150ms ease-in-out;

            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 6px);
                left: calc(50% - 6px);
                width: 12px;
                height: 12px;
                background: url('../../assets/check.svg') center no-repeat;
                transition: 75ms ease-in-out;
                opacity: 0;
            }
        }

        &.error {

            pre {
                border-color: $red;
            }
        }
    }
</style>